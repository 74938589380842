// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apollo-js": () => import("./../../../src/pages/apollo.js" /* webpackChunkName: "component---src-pages-apollo-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cargo-delivery-js": () => import("./../../../src/pages/cargo-delivery.js" /* webpackChunkName: "component---src-pages-cargo-delivery-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-services-js": () => import("./../../../src/pages/it-services.js" /* webpackChunkName: "component---src-pages-it-services-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-footprint-js": () => import("./../../../src/pages/our-footprint.js" /* webpackChunkName: "component---src-pages-our-footprint-js" */),
  "component---src-pages-our-networks-js": () => import("./../../../src/pages/our-networks.js" /* webpackChunkName: "component---src-pages-our-networks-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-stevedoring-and-arrastre-js": () => import("./../../../src/pages/stevedoring-and-arrastre.js" /* webpackChunkName: "component---src-pages-stevedoring-and-arrastre-js" */),
  "component---src-pages-terminal-management-js": () => import("./../../../src/pages/terminal-management.js" /* webpackChunkName: "component---src-pages-terminal-management-js" */),
  "component---src-pages-transit-storage-js": () => import("./../../../src/pages/transit-storage.js" /* webpackChunkName: "component---src-pages-transit-storage-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

